<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div
                    class="partner-box-holder"
                    v-if="partners && Object.keys(partners).length > 0"
                >
                    <div class="box">
                        <div class="title">{{ partners.name }}</div>
                        <div class="row">
                            <div class="col-5">
                                <strong>Szolgáltatások:</strong>
                            </div>
                            <div class="col" v-html="partners.services"></div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <strong>Telefonszám:</strong>
                            </div>
                            <div class="col">
                                {{ partners.phone }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <strong>Email cím:</strong>
                            </div>
                            <div class="col">
                                {{ partners.email }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <strong>Weboldal:</strong>
                            </div>
                            <div class="col">
                                {{ partners.website }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "../core/services/api.service";

export default {
    name: "Partner",
    data() {
        return {
            partners: []
        };
    },
    components: {},
    beforeMount() {
        this.getPartners();
    },
    watch: {
        $route: function() {
            this.getPartners();
        }
    },
    methods: {
        getPartners() {
            ApiService.get(
                `/category/partners/${this.$route.params.id}`
            ).then(response => {
                this.partners = response.data.data;
            });
        }
    }
};
</script>

<style scoped></style>
